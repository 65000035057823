.faq-wrapper h1 {
	text-align: center;
	margin: 50px auto;
}

.faq-wrapper .read-more-faq {
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	text-transform: uppercase;
	width: 100%;
	flex-shrink: 0;
	text-align: center;
	margin: 26px 0 65px 0;
}

.faq-wrapper .read-more-faq:hover {
	color: #fff !important;
	background: none;
	-webkit-background-clip: inherit;
	-webkit-text-fill-color: inherit;
	text-decoration: none;
}

.faq-wrapper .faqs {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.faq-wrapper .faqs > div {
	width: 100%;
	flex-shrink: 0;
}

.faq-wrapper .faq-header {
	border-radius: 15px;
	height: 65px;
	font-size: 16px;
	line-height: 19px;
	color: #fff;
	text-transform: uppercase;
	padding: 0 60px 0 28px;
	display: flex;
	align-items: center;
	cursor: pointer;
	position: relative;
	background: linear-gradient(180deg, #2D325A 0%, #2D325A 100%);
}

.faq-wrapper .faq {
	margin: 0 0 10px 0;
	border-radius: 15px;
	height: 68px;
}

.faq-wrapper .faq-content {
	padding: 28px 26px;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	color: #fff;
	display: none;
	background: linear-gradient(180deg, #2D325A 0%, #2D325A 100%);
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
}

.faq-wrapper .faq-header:after {
	content: url(../images/faq-arr-d.png);
	position: absolute;
	margin: auto;
	left: auto;
	right: 28px;
	bottom: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 26px;
}

.faq-wrapper .expanded .faq-header {
	background: linear-gradient(0deg, #9D279A 0%, #5459C6 100%);
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

	.faq-wrapper .expanded .faq-header:after {
		content: url(../images/faq-arr-up.png);
	}

/*.faq-wrapper .expanded .faq-content {
	display: block;
}*/

.faq-wrapper .faq.expanded {
	height: auto;
}


@media screen and (max-width: 1470px) {
	.faq-wrapper .faqs {
		padding: 0 30px;
	}
}

@media screen and (max-width: 1000px ) {
	.faq-wrapper .faqs > div {
		width: 100%;
	}

	.faq-wrapper .faqs {
		padding: 0 15px;
	}

	.faq-wrapper h1 {
		font-size: 24px;
		line-height: 30px;
		margin: 33px 0 24px 0;
	}

	.faq-wrapper .read-more-faq {
		order: 99;
		margin: 16px auto 30px auto;
		text-align: center;
	}

	.faq-wrapper .faq-content {
		font-size: 16px;
		line-height: 20px;
		padding: 15px;
	}

	.faq-wrapper .faq-header {
		padding: 0 30px 0 15px;
	}

		.faq-wrapper .faq-header:after {
			right: 15px;
			content: url(../images/faq-arr-d-m.png);
		}

	.faq-wrapper .expanded .faq-header:after {
		content: url(../images/faq-arr-up-m.png);
	}
}