.toggler:after {
    background: url(../images/section-toggle-down.png) no-repeat right center;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 100%;
}

.toggler.opened:after {
    background: url(../images/section-toggle-up.png) no-repeat right center;
}