.about-us-wrapper {
	color: #fff;
	padding-top: 140px;
	text-align: center;
	max-width: 940px;
	margin: auto;
	width: calc(100% - 30px);
}

.about-us-wrapper .page-header h1 {
	font-weight: bold;
	font-size: 64px;
	line-height: 76px;
	margin: 10px 35px;
}

.about-us-wrapper .page-content h1 {
	font-weight: bold;
	font-size: 36px;
	line-height: 43px;
	background: -webkit-linear-gradient(#F24659, #EF7540);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about-us-wrapper p {
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin: 50px 0;
}

.about-us-wrapper p:first-of-type{
	font-weight: 600;
}

@media screen and (max-width: 1000px) {
	.about-us-wrapper {
		padding-top: 70px;
	}

	.about-us-wrapper .page-header h1 {
		font-size: 26px;
		line-height: 30px;
	}

	.about-us-wrapper p {
		font-size: 16px;
		line-height: 20px;
		margin: 30px 0;
	}

	.about-us-wrapper .page-content h1 {
		font-size: 22px;
		line-height: 28px;
	}

}