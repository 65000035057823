@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

@font-face {
  font-family: SFPro;
  src: url(./styles/fonts/SF-Pro-Display-Bold.otf) format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url(./styles/fonts/SF-Pro-Display-Light.otf) format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: SFPro;
  src: url(./styles/fonts/SF-Pro-Display-Medium.otf) format("truetype");
  font-weight: 500;
  font-display: swap;
}

@media screen and (min-width: 1000px) {
  @font-face {
    font-family: SFPro;
    src: url(./styles/fonts/SF-Pro-Display-Regular.otf) format("truetype");
    font-weight: 400;
    font-display: swap;
  }
}

body {
  position: relative;
  margin: 0;
  font-family: SFPro, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
  background: #171a32;
}
blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 1rem 0 ;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 2rem;
  font-weight: bold;
}
