.background-img-women {
  background-image: url(../images/main_banner-women.jpg);
}

.background-img-asian {
  /*background-image: url(/../content/images/main_banner-asian.png);*/
}

.review-wrapper {
  margin: 0 30px 30px 30px;
  background: #2d325a;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  background: linear-gradient(180deg, #2d325a 0%, #3b4064 104.45%);
}

.visit-site-gradient {
  position: absolute;
  top: 0;
  right: 0;
}

.review-main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 136px;
  z-index: 2;
}

.review-main > div {
  width: 25%;
  flex-shrink: 0;
  text-align: center;
  z-index: 2;
}

.review-container {
  position: relative;
}

.review-number {
  font-style: normal;
  font-weight: bold;
  font-size: 137px;
  line-height: 164px;
  text-transform: uppercase;
  color: #2a2e55;
  opacity: 1;
  position: absolute;
  bottom: -43px;
  left: -5px;
}

.review-number-0,
.review-number-1,
.review-number-2 {
  color: #464c75;
}

.review-main > div:nth-of-type(3) {
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}

.review-arrow-wrapper {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  height: 191px;
  width: calc(100% - 60px);
}

.review-arrow {
  width: calc(100% / 3);
  background: url(../images/review-arrow-bg.png) no-repeat center center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
}

.review-arrow:first-of-type {
  background-position: left;
}

.review-arrow:first-of-type:after {
  display: none;
}

.review-arrow:last-of-type:after {
  display: none;
}

.review-arrow:last-of-type {
  background-position: right;
}

.review-arrow-btn {
  width: 39px;
  cursor: pointer;
  height: 19px;
  background: url(../images/review-arrow.png) no-repeat center;
  position: absolute;
  bottom: 25px;
  text-align: center;
  transform: rotate(0deg);
  /*transition: transform 0.5s linear;*/
}

.expanded .review-arrow-btn {
  transform: rotate(180deg);
  /*transition: transform 0.5s linear;*/
}

.review-expansion {
  /*transition: max-height 0.5s ease-in;*/
  margin-bottom: 30px;
}

.grid {
  display: grid;
}

.grid-item-2 {
  grid-template-columns: 50% 50%;
}

.grid-item-3 {
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.expanded .review-expansion {
  /*max-height: 500px;
	transition: max-height 0.5s ease-in;*/
  margin-bottom: 30px;
}

.review-expansion > div {
  z-index: 2;
  position: relative;
}

.review-bullets,
.short-description {
  padding-left: 70px;
  margin-right: 30px;
}

.hidden-review {
  display: none;
}

.swiper-container {
  width: 100%;
  height: 167px;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 589px;
}

.news-swiper {
  height: 50px;
  width: 100%;
  max-width: none;
}

.news {
  background: #171a32;
  border-radius: 5px 5px 0px 0px;
  padding: 0 30px;
  position: relative;
}

.news-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.news span {
  background: rgba(121, 132, 190, 0.5);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  width: 1px;
  height: 22px;
  position: absolute;
  left: 210px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.news-date {
  background: -webkit-linear-gradient(#f24659, #ef7540);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  width: 185px;
  text-transform: uppercase;
}

.news-text {
  color: #ffffff !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-left: 50px;
  text-decoration: none;
}

.news-swiper .swiper-button-next,
.news-swiper .swiper-button-prev {
  left: 200px;
  width: 30px;
  height: 20px;
  bottom: 5px;
  top: auto;
  background: url(../images/news-down.png) no-repeat center 3px;
}

.news-swiper .swiper-button-prev {
  bottom: auto;
  top: 26px;
  background: url(../images/news-up.png) no-repeat center bottom;
}

.news-swiper .swiper-button-next:after,
.news-swiper .swiper-button-prev:after {
  font-size: 0;
}

.swiper-slide {
  border-radius: 15px;
  overflow: hidden;
}

.swiper-main {
  padding: 0 50px;
  position: relative;
  margin-right: 7px;
}

.swiper-button-next,
.swiper-button-prev {
  color: #f36c36;
  z-index: 2;
}

.swiper-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.short-description {
  font-size: 18px;
}

.show-more-reviews {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #f26c36;
  padding: 56px 0 20px 0;
  cursor: pointer;
}

.col-1 {
  width: 33%;
  max-width: 320px;
}

.col-2 {
  width: 40%;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.col-3 {
  width: 370px;
}

ul {
  padding: 0 20px;
  margin: 0;
}

.review-bullet {
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  /*color: #FFFFFF;
    list-style-image: url(/../content/images/orange-ellipse.png);
    display: list-item;*/
  margin: 0 15px;
}

.review-bullet:before {
  content: '\2022';
  color: #f05a4e;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.header-month {
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  font-size: 25px;
}

.header-inner-wrap {
  max-width: 650px;
}

.imlive-hp-bg {
  background: url(../images/imlivebg.png) no-repeat right;
  height: 100%;
  position: absolute;
  display: block;
  width: 100%;
}

.desktop-block {
  padding: 10px 15px;
}

.mike-bl {
  min-width: 95px;
}

.cammer-bl {
  min-width: 120px;
}

.read-more {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #f26c36;
}

/*.review-bullet:before {
	content: "";
	height: 6px;
	width: 6px;
	background: #EF763F;
	display: inline-block;
	border-radius: 99px;
	top: -2px;
	position: relative;
	margin-right: 9px;
	background: linear-gradient(180deg, #F24559 0%, #EF763F 100%);
}*/

.siteimg {
  margin-left: 70px;
}

.review-main > div:first-of-type {
  text-align: left;
}
.col-border {
  background: #e2e2e2;
  width: 1px;
  height: 80px;
  margin: 0 20px;
  flex-shrink: 0;
}

.col-1 .siteimg {
  max-width: 130px;
}

.visitSite {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: auto !important;
  max-width: 250px;
  height: 65px;
  border-radius: 40px;
  font-weight: 600;
  text-decoration: none !important;
  font-size: 16px !important;
  line-height: 19px !important;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 10;
  margin-right: 57px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 25px;
}

.swiper-button-next:focus,
.swiper-button-prev:focus {
  outline: 0;
}

.review-wrapper .flex-container {
  text-align: center;
}

.reviews-list {
  background: #25294a;
  padding: 26px 0 35px 0;
  border-radius: 0 0 5px 5px;
}

.reviews-list-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 30px;
  height: 19px;
  margin-bottom: 26px;
}

.reviews-list-header > span {
  background: rgba(121, 132, 190, 0.5);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  width: 1px;
  height: 22px;
  display: block;
  flex-shrink: 0;
}

.reviews-list-header > div {
  width: 25%;
  flex-shrink: 0;
  text-align: center;
}

.main-background img:first-of-type {
  opacity: 0;
}

@media only screen and (max-width: 1200px) {
  .flex-container {
    display: flex;
    flex-flow: row wrap;
    flex-flow: column;
  }

  .flex-item:nth-of-type(1) {
    order: 1;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .flex-item:nth-of-type(2) {
    order: 2;
    margin: 0 auto;
  }

  .creditBtn {
    padding: 20px 20px;
  }

  .buttom-bnr {
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-block.d-flex {
    display: flex;
    align-items: center;
  }

  .col-2 {
    display: none;
  }

  .col-3 .creditBtn {
    display: none;
  }

  .col-border {
    display: none;
  }

  .col-3 {
    text-align: center;
  }

  .col-1 {
    width: inherit;
  }

  .left-wrap {
    width: 100%;
    text-align: center;
    padding: 0 10px;
    border-right: 1px solid #e2e2e2;
  }

  .right-wrap {
    width: 150%;
    text-align: center;
    padding: 0 10px;
  }

  .mobile-block .btn-default {
    background: inherit;
    color: #f34858;
    border-radius: 0;
    width: 100%;
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 0;
    padding: 0;
  }

  .mobile-block .read-more {
    color: inherit;
    padding: 15px 0;
    font-size: 17px;
    text-decoration: none;
    width: 100%;
    display: inline-block;
  }

  .d-flex {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1366px) {
  .siteimg {
    margin-left: 60px;
    max-width: 140px;
  }

  .mikes-score {
    width: 85px;
    padding-left: 30px;
  }

  .btn-default {
    font-size: 18px;
    line-height: 22px;
  }
}

@media only screen and (max-width: 500px) {
  .col-3 {
    width: inherit;
    margin-left: 8px;
  }

  .mike-bl,
  .cammer-bl {
    font-size: 14px;
  }

  .cammer-bl {
    margin-left: 5px;
    min-width: 105px;
  }

  .mike-bl {
    min-width: 90px;
  }

  .desktop-block {
    padding: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .review-wrapper {
    margin: 0;
    border-radius: 0;
    border-top: 2px solid rgba(121, 132, 190, 0.5);
    box-sizing: border-box;
  }

  .review-wrapper:last-of-type {
    border-bottom: 2px solid rgba(121, 132, 190, 0.5);
  }

  .container {
    margin: 0 !important;
  }

  .reviews-list {
    padding: 0;
  }

  .review-main {
    min-height: 199px;
    height: auto;
    flex-wrap: wrap;
  }

  .review-wrapper:before {
    content: '';
    width: 50%;
    height: 100%;
    background: #25294a;
    position: absolute;
  }

  .review-main > div:nth-of-type(1) {
    order: 1;
    text-align: center;
  }

  .review-main > div:nth-of-type(2) {
    order: 3;
    margin-bottom: 20px !important;
  }

  .review-main > div:nth-of-type(3) {
    order: 2;
  }

  .review-main > div:nth-of-type(4) {
    order: 4;
  }

  .review-main > div {
    width: 50%;
  }

  .visitSite {
    width: 150px;
    height: 39px;
    margin: -30px auto auto auto;
  }

  .siteimg {
    margin: auto;
    max-width: 150px;
  }

  .show-more-reviews {
    padding: 16px 0;
  }

  .imlive-hp-bg {
    display: none;
  }
}
