.posts-wrap h1 {
	font-weight: bold;
	font-size: 36px;
	line-height: 43px;
	text-align: center;
	margin: 35px 0 50px 0;
	width: 100%;
}

.posts-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 0 85px 0;
}

.post-wrap {
	width: calc(100%/3 - 20px);
}

.post-image {
	height: 200px;
	width: 100%;
	border-radius: 15px;
	overflow: hidden;
}

.post-image img{
	object-fit: cover;
    width: 100%;
    height: 100%;
}

.post-wrap h4 {
	font-weight: bold;
	font-size: 28px;
	line-height: 33px;
	text-align: left;
	color: #fff;
	margin: 24px 0 18px 0;
	text-decoration: none;
}

.post-wrap .post-short-review {
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	color: #fff;
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.post-wrap .post-short-review a{
	color: #fff;
}

.blog-read-more {
	font-weight: 500;
	font-size: 18px;
	line-height: 24px;
	color: #F26C36 !important;
	margin: 13px 0 0 0;
	display: block;
}

@media screen and (max-width: 1410px) {
	.posts-wrap {
		padding: 0 30px 50px 30px;
	}
}

@media screen and (max-width: 1000px) {
	.posts-wrap {
		display: block;
		padding: 0 15px;
		margin: 0;
	}

	.post-wrap {
		width: 100%;
		margin: 0 0 30px 0;
	}

	.posts-wrap h1 {
		font-size: 24px;
		line-height: 30px;
		margin: 30px 0 25px 0;
	}

	.post-wrap h4 {
		font-size: 20px;
		line-height: 24px;
		margin: 15px 0 10px 0;
	}

	.post-wrap .post-short-review {
		font-weight: 300;
		font-size: 16px;
		line-height: 20px;
	}

	.blog-read-more {
		margin: 7px 0 0 0;
	}

	.post-image {
		height: 146px;
	}
}

@media screen and (max-width: 999px ) and (min-width: 450px) {
	.post-image {
		height: 230px;
	}
}