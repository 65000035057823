input,
textarea,
button,
select,
li,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  padding: 0 20px;
  margin: 0;
}

.no-underline {
  text-decoration: none;
}

.visitSite,
.col-1 a,
#header a,
.post-wrap a,
.review-header a.no-underline {
  color: inherit;
}

.visitSite {
  color: #fff !important;
}

.visitSite:hover {
  border: 2px solid #9d279a !important;
  box-sizing: border-box !important;
  background: none !important;
}

.main-wrapper {
  width: 100%;
  margin: auto;
  /*margin-bottom: 120px;*/
  background: linear-gradient(90deg, #0b1039 0%, #540d51 100%);
}

.header-content>ul {
  display: flex;
  margin: 10px 10px 0 10px;
  padding: 0;
  height: 62px;
  /*background: url(/../content/images/header-bg.png) no-repeat center center;*/
  color: #383838;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  border-radius: 10px;
}

.redirect-pop {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.redirect-pop-inner {
  max-width: 500px;
  max-height: 500px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 2px 2px 30px -7px #000;
}

.animated-gif {
  width: 100%;
}

img.poplogo {
  max-width: 170px;
}

.pop-up-logo {
  position: absolute;
  top: 108px;
  left: 0;
  right: 0;
  margin: auto;
  width: 233px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.header-content ul li {
  display: block;
}

.header-content>ul ul {
  display: none;
}

.showOnMobile {
  display: none !important;
}

.header-content>ul li {
  margin: 0 15px;
  cursor: pointer;
}

.header-content>ul li:hover {
  color: #fff;
}

.header-content>ul li.header-logo {
  margin-left: 30px;
}

.header-logo a {
  font-size: 0;
  display: block;
}

.header-logo img {
  max-width: 240px;
}

.header-content>ul li.languages-menu {
  margin: auto;
  margin-right: 45px;
}

.niches-menu:hover,
.niches-menu.active {
  color: #fff;
}

.about-menu:hover {
  color: #fff;
}

.dd-menu {
  position: relative;
}

.real-dd-menu:before {
  content: url(../images/arr-d.png);
  height: 12px;
}

.real-dd-menu.active:before {
  animation: rotate180 1s linear;
  transform: rotate(180deg);
}

@keyframes rotate180 {
  100% {
    transform: rotate(180deg);
  }
}

.header-content ul li.dd-menu {
  display: flex;
  height: 100%;
  align-items: center;
}

.sub-menu {
  position: absolute;
  left: -35px;
  top: 95px;
  padding: 0;
  color: #fff;
  background: linear-gradient(180deg, #2d325a 0%, #3a3e63 77.14%);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px 0;
  width: 245px;
  left: 50%;
  transform: translateX(-50%);
}

.header-content .sub-menu li {
  margin: 0;
  font-weight: 500 !important;
  padding: 12px 20px !important;
  text-transform: initial !important;
}

.sub-menu li:hover {
  color: #000;
}

.header-content .niches-menu .sub-menu li {
  text-transform: capitalize;
}

.niches-menu .sub-menu {
  width: 200px;
}

.languages-menu:hover,
.languages-menu.active {
  color: #fff;
}

.homepage .footer-content {
  /*margin-bottom: 30px;
    margin-top: -135px;*/
}

.header-content .sub-menu li.lng {
  background-repeat: no-repeat;
  background-position: left center;
  margin: 20px;
  padding: 10px 0 10px 30px;
  line-height: 23px;
  font-size: 15px;
}

.es {
  background-image: url(../images/es-lng.png);
}

.fr {
  background-image: url(../images/fr-lng.png);
}

.it {
  background-image: url(../images/it-lng.png);
}

.en {
  background-image: url(../images/en-lng.png);
}

li.dd-menu.active::after {
  content: '';
  font-size: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 2px;
  height: 46px;
  background: linear-gradient(180deg, #f24559 0%, #ef763f 100%);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
  top: 0;
}

li.real-dd-menu.active::after {
  display: none;
}

.real-dd-menu {
  display: flex;
  flex-flow: row-reverse;
}

.container {
  max-width: 1410px;
  position: relative;
  color: #fff;
}

.body-content a {
  color: #f26c36;
}

.homepage .container {
  margin: -15% auto 0 auto;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.btn-default {
  color: #fff !important;
  margin: 0 15px;
  display: block;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  max-width: 255px;
  text-align: left;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.btn-default:before {
  content: url(../images/gift.png);
  margin-right: 15px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.btn-default:hover:before {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /* When the animation is finished, start again */
  animation-iteration-count: 1;
}

.bold {
  font-weight: bold;
}

.ml-2 {
  margin-left: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.mt-05 {
  margin-top: 5px;
}

.mt-1 {
  margin-top: 10px;
}

.d-flex {
  display: flex;
  align-items: center;
}

.d-inline-block {
  display: inline-block;
}

.score-star:after {
  content: '';
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  left: 5px;
  top: 1px;
}

.star-icon-5:after {
  background-image: url(../images/star-icon-5.png);
}

.star-icon-4:after {
  background-image: url(../images/star-icon-4.png);
}

.star-icon-3:after {
  background-image: url(../images/star-icon-3.png);
}

.star-icon-2:after {
  background-image: url(../images/star-icon-2.png);
}

.star-icon-1:after {
  background-image: url(../images/star-icon-1.png);
}

.main-background>img {
  width: 100%;
  max-width: 1920px;
}

.main-background {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hideOnMobile.header-image,
.showOnMobile.header-image {
  width: 100%;
  padding-top: 54%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -50px center;
}

.mobile-block {
  display: none;
}

.languages-menu span {
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 30px;
  display: block;
  line-height: 23px;
  font-size: 15px;
}

#header {
  transition: all 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.scrollUp {
  transform: translateY(-80px);
}

.header-panel {
  width: calc(100% - 60px);
  max-width: 1810px;
  margin: auto;
  border-radius: 10px;
  margin-top: 0;
  z-index: 2;
  height: 112px;
  display: flex;
  align-items: center;
  position: relative;
}

#menu ul li {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-weight: 600;
}

#menu>ul li {
  padding-top: 56px;
}

#menu a {
  text-decoration: none;
}

.footerLinks a {
  margin: 0 30px;
  flex-shrink: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  text-decoration: none;
  color: #8793d2;
}

.footerLinks a:hover {
  color: #fff;
}

.footerLinks a:last-of-type {
  margin: 0;
}

.footer-content {
  margin-bottom: 0;
  background: #25294a;
  position: relative;
  z-index: 2;
}

.homepage .footer-content {
  padding-bottom: 120px;
}

.homepage .container {
  max-width: none;
}

.homepage .top-container {
  max-width: 1610px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.full-width-container {
  background: linear-gradient(90deg, #0b1039 0%, #540d51 100%);
  padding-top: 55px;
  margin-top: 55px;
}

#menu .red-item.active a {
  color: #fff;
}

#lng-dd-menu {
  display: none;
}

.homepage .faq-wrapper {
  max-width: 900px;
  margin: auto;
}

a.header-btn {
  padding: 8px 20px;
  border-radius: 99px;
  color: #fff;
  font-weight: bold;
  background: #aaaaaa;
  background: -moz-linear-gradient(top, #aaaaaa 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #aaaaaa 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #aaaaaa 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aaaaaa', endColorstr='#ffffff', GradientType=0);
  box-shadow: 3px 3px 21px -4px #000;
  text-shadow: 0 0 20px #000;
  font-display: swap;
}

.header-content-wrap {
  margin: auto;
  position: absolute;
  top: 23%;
  max-width: 1810px;
  width: calc(100% - 60px);

}

.header-content-wrap h1 {
  text-transform: capitalize;
  margin: 0 auto;
  font-weight: 500;
  font-size: 65px;
  line-height: 65px;
  color: #fff;
}

.header-content-wrap h2 {
  font-weight: 300;
  font-size: 30px;
  line-height: 32px;
  color: white;

}

.gradient-text {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  background: #8793d2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.orange-background-gradient {
  background: linear-gradient(0deg, #9d279a 0%, #5459c6 100%);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
}

.footer-content .footerLinks {
  width: 100%;
  max-width: 1410px;
  margin: auto;
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: space-between;
}

.footerLinks p {
  width: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #8793d2;
}

.blog-read-more {
  text-decoration: underline !important;
}

.post-wrap a {
  text-decoration: none;
}

.read-more-faq:hover {
  color: #fff !important;
}

@media only screen and (max-width: 1620px) {

  .hideOnMobile.header-image,
  .showOnMobile.header-image {
    width: 100%;
    padding-top: 56%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top -50px center;
  }

  
}


@media only screen and (min-width: 1001px) {
 

  #menu {
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    display: block !important;
  }

  #menu>ul {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }

  #mobile-home-link {
    display: none;
  }

  #lng-dd-menu {
    order: 3;
    margin-right: 30px;
    margin-left: auto;
  }

  .li-mike {
    order: 1;
  }

  .li-blog {
    order: 4;
  }

  #niche-dd-menu {
    order: 3;
    margin: 0 20px;
  }

  #about-dd-menu {
    order: 2;
    margin: 0 20px;
  }

  .header-logo {
    margin: 20px 0 0 0;
  }

  #lng-sub-menu {
    left: auto;
    right: 20px;
    position: absolute;
    top: 100%;
  }

  .red-item {
    margin: 0 20px;
  }

  .li-blog {
    margin-right: 0;
  }

  .red-item:hover {
    color: #fff;
  }

  .dd-menu-content {
    /*order: 2;*/
    align-self: end;
    margin-top: 62px;
    /*margin-left: -120px;*/
    z-index: 10;
  }

  #niche-sub-menu {
    position: absolute;
  }

  #about-sub-menu {
    position: absolute;
  }

  .sub-menu {
    display: none;
  }

  .orange-background-gradient:hover {
    background: linear-gradient(360deg, #f24559 0%, #ef763f 100%);
  }

  #menu li:hover {
    color: #fff;
  }

  .btn-default:hover {
    text-decoration: underline;
  }

  .show-more-reviews:hover {
    color: #fff !important;
  }

  .post-wrap h4:hover {
    background: -webkit-linear-gradient(#f24659, #ef7540);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }

  .blog-read-more:hover {
    color: #fff !important;
  }
}

.mt-10 {
  padding: 140px 0 50px 0;
  margin: auto;
  width: calc(100% - 60px);
}

.review-header {
  max-width: 670px;
}

.tag {
  position: absolute;
  top: -6px;
  left: 25px;
  z-index: 2;
}

.btn-black {
  background: #000;
  color: #fff;
  border-radius: 99px;
  padding: 2px 30px;
  font-size: 14px;
}

.rank-vote {
  margin: 10px 0;
}

.site-rank {
  width: 196px;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px 15px;
  min-height: 80px;
  margin: 20px 0;
  position: relative;
}

.right-block {
  text-align: center;
  width: 330px;
  z-index: 3;
}

.body-content {
  min-height: calc(100vh - 90px);
}

.sites-recomended {
  padding: 1px 0 1px 0;
  width: 330px;
  background: #25294a;
  border-radius: 15px;
}

.sites-recomended a {
  margin-bottom: 20px;
  background: linear-gradient(180deg, #2d325a 0%, #3b4064 104.45%);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 30px 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.sites-recomended a:hover {
  background: linear-gradient(180deg, #575e94 0%, #353b69 104.45%);
}

.sites-recomended-bnr {
  margin-bottom: 40px;
}

.sites-recomended>div {
  margin: 26px 0;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  line-height: 19px;
  text-transform: uppercase;
}

.sites-recomended>img:first-of-type {
  max-width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.rank-vote label {
  font-size: 15px;
  color: #f34858;
  font-weight: bold;
  position: relative;
  top: -1px;
}

.rank-vote input[type='radio'] {
  position: relative;
}

.rank-vote input[type='radio']:before {
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #f34858;
  display: inline-block;
  background: #fff;
  border-radius: 99px;
}

.rank-vote input[type='radio']:checked:after {
  content: '';
  width: 4px;
  height: 4px;
  border: 1px solid #f34858;
  display: inline-block;
  background: #f34858;
  border-radius: 99px;
  position: absolute;
  left: 3px;
  top: 3px;
}

.loader-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  height: 51px;
}

.thank-you {
  display: none;
  line-height: 80px;
  font-weight: 600;
}

.rateBtn:hover {
  cursor: pointer;
}

.d-block {
  display: block;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 53px;
  height: 53px;
}

.buttom-bnr img {
  width: auto;
  max-width: 100%;
}

.buttom-bnr {
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 3;
}

.homepage .buttom-bnr {
  filter: drop-shadow(0px -8px 10px rgba(23, 26, 50, 0.6));
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 100px;
  overflow: hidden;
}

.homepage .buttom-bnr a {
  width: 100%;
  height: 100%;
  display: block;
}

.homepage .buttom-bnr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.homepage #scroll-top {
  bottom: 110px;
}

.homepage .buttom-bnr .close-bnr {
  content: '-' !important;
  font-size: 40px;
  position: absolute;
  top: 0;
  right: 10px;
  color: #fff;
  line-height: 20px;
  width: 30px;
  cursor: pointer;
}

.homepage .buttom-bnr .close-bnr:after {
  content: '-' !important;
}

.homepage .buttom-bnr .minimized {
  display: none;
}

.homepage .buttom-bnr.collapsed .close-bnr:after {
  content: '+';
  font-size: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 2px;
  position: relative;
  bottom: 3px;
}

.homepage .buttom-bnr.collapsed {
  height: 30px;
}

.homepage .img-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 41px;
  height: 41px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bottom-banner {
  width: 100%;
  height: 100%;
  background-size: cover;
}

@media only screen and (min-width: 1281px) {
  #menu {
    width: auto;
    left: auto;
    right: 0;
    top: 0;
  }
}

@media only screen and (max-width: 1410px) {
  .footerLinks {
    padding: 0 30px;
    box-sizing: border-box;
  }

  .header-content-wrap {
    width: 95%;
  }

  .header-content-wrap h1 {
    font-size: 40px;
    line-height: 45px;
  }

  .header-inner-wrap .header-month {
    font-size: 18px;
  }

  .header-content-wrap h2 {
    font-size: 20px;
    margin: 8px 0;
    line-height: 26px;
  }

  .header-content-wrap .header-inner-wrap {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1366px) {
  .homepage .buttom-bnr {
    height: 70px;
  }

  .homepage .buttom-bnr.collapsed {
    height: 21px;
  }

  .homepage .buttom-bnr .close-bnr {
    top: -4px;
  }
  .homepage .top-container {
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100%);
  }
}

@media only screen and (max-width: 1150px) {
  .homepage .container {
    margin: -15% auto 0 auto !important;
  }
}

.scroll-top {
  position: fixed;
  bottom: 13px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background: url(../images/scroll-top.png) no-repeat center center;
  color: white;
  cursor: pointer;
  padding: 0;
  font-size: 18px;
  width: 80px;
  height: 80px;
}

.statick-bg {
  position: fixed;
  width: 100%;
  padding-top: 46%;
}

@media only screen and (max-width: 1000px) {
  .body-content {
    min-height: auto;
    margin-bottom: 70px;
  }

  .mt-10 {
    padding: 70px 0 50px 0;
    width: calc(100% - 30px);
  }

  #header {
    display: flex;
  }

  .header-panel {
    justify-content: space-between;
    height: 70px;
    width: calc(100% - 30px);
  }

  .mobile-block {
    display: block;
    box-shadow: 0px 9px 30px -21px inset;
  }

  .buttom-bnr {
    width: 100%;
    left: 0;
  }

  .showOnMobile {
    display: block !important;
  }

  .hideOnMobile {
    display: none !important;
  }

  li.dd-menu.active::after {
    display: none;
  }

  #menu-button {
    margin-left: 0;
    margin-right: 30px;
    width: 26px;
    height: 26px;
    background: url('../images/hamburger.png') no-repeat center center;
    z-index: 1000;
  }

  #menu-button.clicked {
    background-image: url('../images/x-but.png');
  }

  .header-content>ul li.header-logo {
    margin: 0;
  }

  .header-content>ul ul {
    display: block;
  }

  .header-panel {
    position: initial;
  }

  #menu {
    position: absolute;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(6px);
    top: 0;
    width: 100%;
    margin: 0;
    height: auto;
    display: none;
    overflow: scroll;
  }

  .homepage .buttom-bnr .close-bnr {
    display: none;
  }

  .homepage .buttom-bnr {
    height: 70px;
  }

  #menu>ul {
    border-radius: 10px;
    width: calc(100% - 20px);
    padding: 0;
    margin: 82px auto auto auto;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  #menu a,
  #menu .red-item {
    color: #fff;
    text-align: center;
    font-weight: 600;
    text-decoration: none;
    width: 100%;
  }

  #menu .sub-menu {
    display: none;
    width: 100%;
    text-align: center;
    transform: none;
  }

  #menu .real-dd-menu {
    color: #fff;
    width: 100% !important;
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  niches-menu #menu ul>li {
    padding: 12px 30px 12px 30px;
  }

  #menu .languages-menu {
    color: #fff;
  }

  #menu .dd-menu {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 12px 30px 12px 30px;
    line-height: 23px;
    order: 3;
  }

  #menu .li-home {
    order: 1;
  }

  #menu .li-mike {
    order: 2;
  }

  .sub-menu {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    padding: 0;
    background: none;
    margin: 0;
    color: #ef7540;
  }

  .languages-menu .sub-menu {
    display: block;
    left: -20px;
  }

  #menu ul>li.dd-menu-content {
    padding: 0 30px;
  }

  #menu ul .sub-menu li {
    margin: 0;
  }

  #menu ul .sub-menu li {
    padding: 12px 0;
    text-transform: capitalize;
    color: #8793d2 !important;
  }

  #menu>ul li {
    padding: 12px 0;
  }

  #lng-dd-menu.active {
    color: #ef7540;
    font-weight: 600;
  }

  /*#niche-dd-menu.active {
		color: #ef7540;
		font-weight: 600;
	}*/

  .pc-block {
    display: none;
  }

  #scroll-top {
    position: fixed;
    right: 0;
    bottom: 0px;
  }

  .real-dd-menu:before {
    position: absolute;
    top: 16px;
    left: calc(50% + 50px);
  }

  .real-dd-menu.about-menu:before {
    left: calc(50% + 68px);
  }

  .header-content-wrap {
    top: auto;
  }

  .header-logo img {
    max-width: 140px;
  }

  .header-logo.fixed img {
    z-index: 10000;
    position: fixed;
    top: 26px;
    right: 15px;
  }

  .sub-menu {
    box-shadow: none;
  }

  .btn-default {
    font-size: 18px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 10px auto 0 auto;
    padding: 0 10px 0 10px;
    align-items: center;
    justify-content: center;
    background-size: 34px;
    text-align: center;
    background-position: center 4px;
  }

  .btn-default:before {
    margin: auto;
    width: 100%;
  }

  .header-content-wrap h1 {
    font-size: 25px;
    line-height: 28px;
    text-shadow: 0 0 10px #000;
  }

  .header-inner-wrap .header-month {
    font-size: 14px;
  }

  .header-content-wrap .header-inner-wrap {
    max-width: 360px;
  }

  .footer-content .footerLinks {
    display: flex;
    height: auto;
    flex-wrap: wrap;
  }

  .footerLinks a {
    display: block;
    margin: 20px 0;
    width: 100%;
  }

  .footerLinks a:last-of-type {
    margin: 20px 0;
  }

  .footer-content {
    padding: 20px 0 80px 0;
    text-align: center;
  }

  .footerLinks p {
    margin: 20px 0;
    order: 99;
  }

  .buttom-bnr img {
    width: 100%;
  }

  .homepage .container {
    margin: 0 !important;
  }

  .full-width-container {
    margin: 0;
    padding: 0;
  }

  .review-main>div:nth-of-type(3) {
    padding: 0 !important;
    text-align: center !important;
  }

  .hideOnMobile.header-image,
  .showOnMobile.header-image {
    background-size: cover;
    background-position: top;
  }
}

@media screen and (max-width: 999px) and (min-width: 450px) {
  .mobile-block {
    display: none;
  }

  .pc-block.maximized {
    display: block;
  }

  .homepage .buttom-bnr {
    height: 55px;
  }
}

@media screen and (max-width: 650px) and (min-width: 450px) {
  .homepage .buttom-bnr {
    height: 45px;
  }
}

@media only screen and (max-width: 520px) {
  .header-content-wrap h1 {
    font-size: 20px;
    line-height: 24px;
  }

  .header-content-wrap .header-inner-wrap {
    max-width: 250px;
  }

  .header-content-wrap h2 {
    display: none;
  }

  .redirect-pop-inner {
    max-width: calc(100vw - 40px);
    max-height: calc(100vw - 40px);
  }

  .pop-up-logo {
    top: 21%;
    height: 70px;
  }

  img.poplogo {
    max-width: 130px;
  }

  .close {
    right: 0;
    top: 0;
  }
}

@media only screen and (max-width: 374px) {
  .logo {
    width: 190px;
  }
}