.mikes-score {
  font-size: 50px;
  line-height: 68px;
  color: #fff;
  font-weight: 600;
  width: 112px;
  background: url(../images/larry-score.png) left center no-repeat;
  padding-left: 40px;
}

.reviews-list .mikes-score {
  padding-bottom: 30px;
}

.score-wrap {
  padding-left: 87px;
  margin-top: -7px;
}

.star,
.empty-star {
  /*background: url(/../content/images/star.png) no-repeat center center;*/
  width: 20px;
  height: 20px;
  display: block;
  pointer-events: none;
}

.not-scored .star,
.not-scored .empty-star {
  cursor: pointer;
  pointer-events: auto;
}

.empty-star {
  /*background: url(/../content/images/empty-star.png) no-repeat center center;*/
}

.mike-score-stars {
  display: flex;
  justify-content: space-evenly;
  min-width: 100px;
}

.score-div {
  align-items: center;
  justify-content: space-evenly;
}

.user-votes {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  margin: 4px 0;
}

.user-votes span {
  font-weight: 300;
  color: #ff7034 !important;
}

.please-vote {
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  color: #fff;
}

@media only screen and (min-width: 1001px) {
  .not-scored .star:hover,
  .not-scored .empty-star:hover {
    transform: scale(1.3);
    background: url(../images/star.png) no-repeat center center;
  }
}

@media only screen and (max-width: 1000px) {
  .mikes-score {
    font-size: 36px;
    line-height: 43px;
    background-size: 18px;
    padding-left: 30px;
  }

  .score-wrap {
    padding-left: inherit;
    margin-top: inherit;
  }

  .reviews-list .mikes-score {
    padding-bottom: inherit;
  }
}
